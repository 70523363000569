import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup, FeatureGroup, LayersControl } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L, { latLngBounds } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster'
import './MapStyle.css'
import FacilityPopup from './FacilityPopup';
import { connect } from "react-redux";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

require('react-leaflet-markercluster/dist/styles.min.css');

class MapView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showHospitals: true,
      showPharmacys: true,
      showDoctors: true,
    }
  }

  componentDidMount() {
    if (localStorage.getItem("mapView")) {
      var mapView = localStorage.getItem("mapView");
      if (mapView === "Kliniken") {
        this.setState({
          showPharmacys: false,
        })
      }
      if (mapView === "Apotheken") {
        this.setState({
          showHospitals: false,
        })
      }
    }
    localStorage.removeItem("mapView")
  }


  /*
  {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {},
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [
                -13.974609375,
                35.746512259918504
              ],
              [
                75.41015624999999,
                35.746512259918504
              ],
              [
                75.41015624999999,
                66.79190947341796
              ],
              [
                -13.974609375,
                66.79190947341796
              ],
              [
                -13.974609375,
                35.746512259918504
              ]
            ]
          ]
        }
      }
    ]
  }
  */
  render() {

    var bounds = latLngBounds([
      [47, 6],
      [56, 14]
    ]);

    var marker = this.props.hospitals.concat(this.props.pharmacys)

    if (this.props.hospitals.length > 0 || this.props.pharmacys.length > 0) {
      bounds = latLngBounds([marker[0].position, marker[0].position])
      marker.forEach(marker => {
        bounds.extend(marker.position)
      })
    }

    console.log(bounds)

    var hospitalIcon = L.icon({
      iconUrl: require('../images/hospital_marker.png'),
      iconSize: [60, 42],
      iconAnchor: [15, 11]
    })
    var pharmacyIcon = L.icon({
      iconUrl: require('../images/pharmacy_marker.png'),
      iconSize: [42, 42],
      iconAnchor: [21, 21]
    })
    const createClusterHospitalIcon = function (cluster) {
      return L.divIcon({
        html: `<div><span>${cluster.getChildCount()}</span></div>`,
        className: 'marker-cluster-hospital',
        iconSize: L.point(40, 40, true),
      });
    }
    const createClusterPharmacyIcon = function (cluster) {
      return L.divIcon({
        html: `<div><span>${cluster.getChildCount()}</span></div>`,
        className: 'marker-cluster-pharmacy',
        iconSize: L.point(40, 40, true),
      });
    }
    var doctorIcon = L.icon({
      iconUrl: require('../images/doctor_marker.png'),
      iconSize: [42, 42],
      iconAnchor: [21, 21]
    })
    const createClusterDoctorIcon = function (cluster) {
      return L.divIcon({
        html: `<div><span>${cluster.getChildCount()}</span></div>`,
        className: 'marker-cluster-doctor',
        iconSize: L.point(40, 40, true),
      });
    }
    return (
      <Map bounds={bounds} style={{ height: "831px", width: "100%" }} maxZoom={18} minZoom={4}>
        <LayersControl position="topright">
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png'
          />
          <LayersControl.Overlay name="Kliniken" checked={this.state.showHospitals}>
            <FeatureGroup>
              <MarkerClusterGroup iconCreateFunction={createClusterHospitalIcon}>
                {this.props.hospitals.map((hospital, index) => {
                  return (
                    <Marker position={hospital.position} key={hospital.guid} title={hospital.title} icon={hospitalIcon}>
                      <Popup className="popup">
                        <FacilityPopup {...hospital} />
                      </Popup>
                    </Marker>
                  )
                })}
              </MarkerClusterGroup>
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Apotheken" checked={this.state.showPharmacys}>
            <FeatureGroup>
              <MarkerClusterGroup iconCreateFunction={createClusterPharmacyIcon} >
                {this.props.pharmacys.map((marker, index) => {
                  return (
                    <Marker position={marker.position} key={marker.guid} title={marker.title} icon={pharmacyIcon}>
                      <Popup className="popup">
                        <FacilityPopup {...marker} />
                      </Popup>
                    </Marker>
                  )
                })}
              </MarkerClusterGroup>
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Arztpraxen" checked={this.state.showDoctors}>
            <FeatureGroup>
              <MarkerClusterGroup iconCreateFunction={createClusterDoctorIcon} >
                {this.props.doctors.map((marker, index) => {
                  return (
                    <Marker position={marker.position} key={marker.guid} title={marker.title} icon={doctorIcon}>
                      <Popup className="popup">
                        <FacilityPopup {...marker} />
                      </Popup>
                    </Marker>
                  )
                })}
              </MarkerClusterGroup>
            </FeatureGroup>
          </LayersControl.Overlay>
        </LayersControl>

      </Map>
    )
  }
}

export default connect(
  store => ({
    hospitals: store.HospitalReducer.hospitals,
    pharmacys: store.PharmacyReducer.pharmacys,
    doctors: store.DoctorReducer.doctors,
  }),
  null
)(MapView);