import { SET_PHARMACYS} from '../ActionsTypes';

const initialState = {
    pharmacys: [],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_PHARMACYS: {
            const { pharmacys } = action.payload;
            return {
                ...state,
                pharmacys: pharmacys,
            }
        }
        default: {
            return state;
        }
    }

}