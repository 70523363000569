
export const SET_FETCHING = "SET_FETCHING";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const SET_HOSPITALS = "SET_HOSPITALS";
export const SET_PHARMACYS = "SET_PHARMACYS";
export const SET_DOCTORS = "SET_DOCTORS";
export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";
export const READ_TEXT = "READ_TEXT";