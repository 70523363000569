import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { connect } from "react-redux";
import { addFilter, removeFilter, setHospital, setFetching, setPharmacys, setDoctors } from "../Redux/Actions";
import * as Properties from '../Properties'

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: [],
        }
    }

    loadOrganizations = () => {
        this.props.setFetching(true);
        var unfetchedFilter = this.props.filter.filter(element => !element.fetched)
        unfetchedFilter.forEach(filter => {
            switch (filter.type) {
                case "radial":
                    this.handleRadialSearch(filter, filter.position, filter.dist);
                    break;
                case "fState":
                    this.fetchDataFstate(filter, filter.code)
                    break;
                case "ICD":
                    console.log(filter)
                    this.fetchDataIcd(filter, filter.code)
                    break;
                case "department":
                    this.fetchDataDepartment(filter, filter.code)
                    break;
                default:
                    console.log("wrong filter Type")
            }
        })
        if (this.props.filter.length !== 0 && unfetchedFilter.length === 0) {
            this.props.setHospital(this.combineFilter("hospitals"))
            //this.props.setPharmacys(this.combineFilter("pharmacys"))
            this.props.setDoctors(this.combineFilter("doctors"))
        }
        this.props.setFetching(false);
    }


    combineFilter = (organisationType) => {
        var guidMaps = this.props.filter.map(filter => {
            return filter[organisationType].reduce((acc, curr) => {
                acc.set(curr.key, curr)
                return acc;
            }, new Map())
        })
        var combinedOrganisations = [];
        guidMaps[0].forEach(org => {
            if (guidMaps.every(map => map.get(org.key))) {
                combinedOrganisations.push(org);
            }
        })
        return combinedOrganisations;
    }

    handleRadialSearch = (filter, position, dist) => {

        const API_URL = Properties.service_url + "/medimaps-rest/webapi/organization/v1/search/dist";

        fetch(API_URL + "/" + position.lat + "/" + position.lng + "/" + dist)
            .then(res => res.json())
            .then(organizationJSON => {
                var hospitalArr = [];
                var pharmacyArr = [];
                var doctorArr = [];
                organizationJSON.forEach((organization) => {
                    var marker = {
                        key: organization.guid,
                        name: organization.name,
                        position: { lat: organization.lat, lng: organization.lon },
                        title: organization.name,
                        type: organization.type,
                        address: organization.street + ", " + organization.zip + " " + organization.city,
                        guid: organization.guid,
                        department: organization.department,
                    }
                    if (organization.type === 1) hospitalArr.push(marker);
                    if (organization.type === 2) pharmacyArr.push(marker);
                    if (organization.type === 3) doctorArr.push(marker);
                })
                filter["hospitals"] = hospitalArr;
                filter["pharmacys"] = pharmacyArr;
                filter["doctors"] = doctorArr;
                filter.fetched = true;
                var regionFilter = this.props.filter.find(filter => filter.type === "fState");
                if (regionFilter) {
                    this.props.removeFilter(regionFilter)
                }
                this.props.addFilter(filter);
            });

    }

    fetchDataFstate = (filter, fState) => {

        const API_URL = Properties.service_url + "/medimaps-rest/webapi/organization/v1/search/fState";
        fetch(API_URL + "/" + filter.id)
            .then(res => res.json())
            .then(organizationJSON => {
                var hospitals = [];
                var pharmacys = [];
                var doctorArr = [];
                organizationJSON.forEach((organization) => {
                    var marker = {
                        key: organization.guid,
                        name: organization.name,
                        position: { lat: organization.lat, lng: organization.lon },
                        title: organization.name,
                        type: organization.type,
                        address: organization.street + ", " + organization.zip + " " + organization.city,
                        guid: organization.guid,
                        department: organization.department,
                    }
                    if (organization.type === 1) hospitals.push(marker);
                    if (organization.type === 2) pharmacys.push(marker);
                    if (organization.type === 3) doctorArr.push(marker);
                })
                filter["hospitals"] = hospitals
                filter["pharmacys"] = pharmacys
                filter["doctors"] = doctorArr;
                filter["title"] = fState
                filter.fetched = true;
                var radialFilter = this.props.filter.find(filter => filter.type === "radial");
                if (radialFilter) {
                    this.props.removeFilter(radialFilter)
                }
                this.props.addFilter(filter);
            })
    }

    fetchDataIcd = (filter, code) => {
        console.log(filter)
        const API_URL = Properties.service_url + "/medimaps-rest/webapi/lucene/v1/icd";
        fetch(API_URL + "/" + code)
            .then(res => res.json())
            .then(organizationJSON => {
                var hospitals = [];
                var pharmacys = [];
                var doctorArr = [];
                organizationJSON.forEach((organization) => {
                    var marker = {
                        key: organization.guid,
                        name: organization.name,
                        position: { lat: organization.lat, lng: organization.lon },
                        title: organization.name,
                        type: organization.type,
                        address: organization.street + ", " + organization.zip + " " + organization.city,
                        guid: organization.guid,
                        department: organization.department,
                    }
                    if (organization.type === 1) hospitals.push(marker);
                    if (organization.type === 2) pharmacys.push(marker);
                    if (organization.type === 3) doctorArr.push(marker);
                })
                /* if (type === 2) {
                    this.setState({
                        hospitalsIcd2: hospitals,
                        pharmacysIcd2: pharmacys,
                    })
                }
                if (type === 6) {
                    this.setState({
                        hospitalsIcd6: hospitals,
                        pharmacysIcd6: pharmacys,
                    })
                } */

                filter["hospitals"] = hospitals
                filter["pharmacys"] = pharmacys
                filter["doctors"] = doctorArr;
                filter["title"] = filter.label
                filter.fetched = true;
                this.props.addFilter(filter);
            });
    }

    fetchDataDepartment = (filter, code) => {
        console.log(filter)
        const API_URL = Properties.service_url + "/medimaps-rest/webapi/organization/v1/search/department";
        fetch(API_URL + "/" + code)
            .then(res => res.json())
            .then(organizationJSON => {
                var hospitals = [];
                var pharmacys = [];
                var doctorArr = [];
                organizationJSON.forEach((organization) => {
                    var marker = {
                        key: organization.guid,
                        name: organization.name,
                        position: { lat: organization.lat, lng: organization.lon },
                        title: organization.name,
                        type: organization.type,
                        address: organization.street + ", " + organization.zip + " " + organization.city,
                        guid: organization.guid,
                        department: organization.department,
                    }
                    if (organization.type === 1) hospitals.push(marker);
                    if (organization.type === 2) pharmacys.push(marker);
                    if (organization.type === 3) doctorArr.push(marker);
                })
                /* if (type === 2) {
                    this.setState({
                        hospitalsIcd2: hospitals,
                        pharmacysIcd2: pharmacys,
                    })
                }
                if (type === 6) {
                    this.setState({
                        hospitalsIcd6: hospitals,
                        pharmacysIcd6: pharmacys,
                    })
                } */

                filter["hospitals"] = hospitals
                filter["pharmacys"] = pharmacys
                filter["doctors"] = doctorArr;
                filter["title"] = filter.label
                filter.fetched = true;
                this.props.addFilter(filter);
            });
    }

    render() {
        this.loadOrganizations();
        return (
            <NavDropdown title="Filter" id="basic-nav-dropdown">
                {this.props.filter.map(item => {
                    return (
                        <NavDropdown.Item className={"group-" + item.group} key={item.label}>
                            {item && item.label ? item.label : item.filterLabel}
                            {item && item.dist ? ", " + item.dist + " km" : ""}
                            <Button variant="link" onClick={() => this.props.removeFilter(item)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </NavDropdown.Item>
                    )
                })}
            </NavDropdown>
        )
    }
}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
        filter: store.FilterReducer.filter,
    }),
    { addFilter, removeFilter, setHospital, setFetching, setPharmacys, setDoctors }
)(Filter);
