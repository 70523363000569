import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import * as Service from '../Service';
import { faFilter, faBookmark, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { addBookmark, addFilter, readText } from "../Redux/Actions";

class BodyPartPopup extends Component {


    render() {
        var trans = {
            skin: "Körper",
            respiratory_system: "Atmungssystem",
            lower_respiratory_system: "Atmungssystem der Brust",
            lung: "Lunge",
            respiratory_system_head_neck: "Atmungssystem des Kopfes",
            trachea: "Luftröhre",
            larynx: "Kehlkopf",
        }
        return (
            <Fragment>
                <Row>
                    <Col md={9}>
                        <h5>{trans[this.props.bodyPart]}</h5>
                    </Col>
                    <Col md={3}>
                        {/* <Button className="btn btn-sm btn-secondary mr-1" title="Filtern" onClick={() => this.props.addFilter(this.props)}>
                            <FontAwesomeIcon icon={faFilter} />
                        </Button> */}
                        <Button className="btn btn-sm btn-secondary mr-1" title="Zur Infobox hinzufügen" onClick={() => this.props.addBookmark({ title: trans[this.props.bodyPart] })}>
                            <FontAwesomeIcon icon={faBookmark} />
                        </Button>
{/*                         <Button className="btn btn-sm btn-secondary" title="Vorlesen" onClick={() => this.props.readText(Service[this.props.bodyPart.toLowerCase() + "_text"])}>
                            <FontAwesomeIcon icon={faVolumeUp} />
                        </Button> */}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {Service[this.props.bodyPart.toLowerCase() + "_text"]}
                    </Col>
                </Row>
                {/*                       <Row>
                            <Col md={6}>
                                <Button variant="secondary" onClick={() => this.props.addToInfoBox(this.props.title)}>
                                    Merken
                        </Button>
                            </Col>
                        </Row> */}
            </Fragment>
        )
    }
}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
    }),
    { addBookmark, addFilter, readText }
)(BodyPartPopup);