import { SET_LANGUAGE } from '../ActionsTypes';

const initialState = {
    language: navigator.language,
}

export default (state = initialState, action) => {
    
    switch (action.type) {
        case SET_LANGUAGE: {
            const { language } = action.payload;
            return {
                ...state,
                language: language,
            }
        }
        default: {
            return state;
        }
    }

}
