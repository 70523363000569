import React, { Component, Fragment } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import BodySchema from './BodySchema';
import * as Service from '../Service'
import inside from 'point-in-polygon';
import L from 'leaflet';
import * as Properties from '../Properties'
import BodyPartBox from './BodyPartBox';
import DiseaseBox from './DiseaseBox';

export default class BodySchemaPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      parentParentBodyPart: null,
      parentBodyPart: null,
      activeBodyPart: "skin",
      bodyPartParents: [],
      diseases: [],
      diseasesEtiology: [],
      bodyInformations: [],
      activeDisease: null,
    }
  }

  handleActiveBodyPart = bodyPart => {
    if (Service[bodyPart + "_geoJson"] !== undefined) {
      var parents = this.state.bodyPartParents;
      if (this.state.activeBodyPart !== "skin") {
        parents.push(this.state.activeBodyPart)
      }
      this.loadDiseases(bodyPart);
      this.setState({
        activeBodyPart: bodyPart,
        bodyPartParents: parents,
      })
    }
  }

  handleActiveDisease = disease => {
    this.setState({
      activeDisease: disease,
    })
  }

  loadDiseases = bodyPart => {
    var geoJson = Service[bodyPart + "_geoJson"];
    var children = geoJson.features;
    this.setState({
      bodyInformations: [],
      diseases: [],
    })
    const API_URL = Properties.service_url + "/meidmaps-rest/webapi/foundation/v1/anatomy";
    fetch(API_URL + "/" + Service.body_region_name[bodyPart])
      .then(res => res.json())
      .then(response => {
        response = response.filter(disease => disease.etiology === "")
        var diseases = [];
        var position = [1500, 1000];
        //Aggregate ICD-Codes
        var map = new Map();
        response = response.forEach(disease => { disease.icd10_code = disease.icd10_code.split('.')[0]; map.set(disease.icd10_code, disease) });
        response = [];
        map.forEach(entry => response.push(entry))
        // End of ICD Aggregation
        response.forEach(disease => {
          var marker = { position: position, title: disease.icd10_description_ger, icdCode: disease.icd10_code }
          diseases.push(marker)
        })
        this.setState({
          diseasesEtiology: /*this.state.diseasesEtiology.concat(diseases)*/ diseases,
        })
      })
    children.forEach(child => {
      if (child.properties.name !== undefined && Service.body_region_name[child.properties.name] !== undefined) {
        const API_URL = Properties.service_url + "/medimaps-rest/webapi/foundation/v1/anatomy";
        fetch(API_URL + "/" + Service.body_region_name[child.properties.name])
          .then(res => res.json())
          .then(response => {
            //Aggregate ICD-Codes
            var map = new Map();
            response = response.filter(disease => disease.etiology !== "");
            response = response.forEach(disease => { disease.icd10_code = disease.icd10_code.split('.')[0]; map.set(disease.icd10_code, disease) });
            response = [];
            map.forEach(entry => response.push(entry))
            // End of ICD Aggregation
            var diseases = [];
            var bounds = L.geoJSON(child.geometry).getBounds();
            var position = null;
            response.forEach(disease => {
              position = this.getCenterOfPolygon(child.geometry.coordinates);
              position[0] = position[0] + 80;
              var marker = { position: position, title: disease.icd10_description_ger, icdCode: disease.icd10_code }
              diseases.push(marker)
            })
            var bodyInformation = { position: [this.getCenterOfPolygon(child.geometry.coordinates)[0] - 50, this.getCenterOfPolygon(child.geometry.coordinates)[1]], name: child.properties.name, title: child.properties.title, diseases: diseases, group: 6 }
            this.setState({
              diseases: this.state.diseases.concat(diseases),
              bodyInformations: this.state.bodyInformations.concat(bodyInformation),
            })
          })
      }
    })
  }

  getPostionInPolygon = (bounds, polygon) => {
    var deltaX = bounds._northEast.lng - bounds._southWest.lng;
    var deltaY = bounds._northEast.lat - bounds._southWest.lat;
    var pointFound = false;
    var point = null;
    var counter = 0;
    while (!pointFound && counter < 10) {
      point = [
        bounds._southWest.lat + Math.random() * deltaY * 0.6 + deltaY * 0.2,
        bounds._southWest.lng + Math.random() * deltaX * 0.6 + deltaX * 0.2
      ]
      if (inside(point, polygon)) pointFound = true;
      counter++;
    }
    return point;
  }

  getCenterOfPolygon = polygon => {
    polygon = polygon[0];
    var first = polygon[0], last = polygon[polygon.length - 1];
    if (first[0] !== last[0] || first[1] !== last[1]) polygon.push(first);
    var twicearea = 0,
      x = 0, y = 0,
      npolygon = polygon.length,
      p1, p2, f;
    for (var i = 0, j = npolygon - 1; i < npolygon; j = i++) {
      p1 = polygon[i]; p2 = polygon[j];
      f = (p1[1] - first[1]) * (p2[0] - first[0]) - (p2[1] - first[1]) * (p1[0] - first[0]);
      twicearea += f;
      x += (p1[0] + p2[0] - 2 * first[0]) * f;
      y += (p1[1] + p2[1] - 2 * first[1]) * f;
    }
    f = twicearea * 3;
    return [y / f + first[1], x / f + first[0]];
  }

  handleZoomOut = () => {
    var parents = this.state.bodyPartParents;
    if (parents.length === 0) return;
    var parent = parents.pop();
    this.loadDiseases(parent)
    this.setState({
      activeBodyPart: parent,
      bodyPartParents: parents,
    })
  }

  handleMultiZoomOut = (steps) => {
    console.log(steps)
    console.log(this.state.bodyPartParents)
    var removedParents = this.state.bodyPartParents.splice(0, this.state.bodyPartParents.length - steps + 1);
    console.log(removedParents)
    var parent = removedParents.pop();
    console.log(parent)
    this.loadDiseases(parent)
    this.setState({
      activeBodyPart: parent,
      bodyPartParents: removedParents,
    })
  }

  render() {
    var trans = {
      skin: "Körper",
      respiratory_system: "Atmungssystem",
      lower_respiratory_system: "Atmungssystem der Brust",
      lung: "Lunge",
      respiratory_system_head_neck: "Atmungssystem des Kopfes",
      trachea: "Luftröhre",
      larynx: "Kehlkopf",
    }
    return (
      <Fragment>
        <Col md={12}>
          {/* <Row>
            <Col md={7}>
              {this.state.bodyPartParents.map((parent, index) => {
                var name = trans[parent]
                console.log(name)
                return (
                  <Button key={name} variant="link" onClick={() => this.handleMultiZoomOut(this.state.bodyPartParents.length - index)}>{name}</Button>
                )
              })}
              <div className="bodySchemaTitle">
                <h3>{trans[this.state.activeBodyPart]}</h3>
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col md={7}>
              <BodySchema
                setActiveBodyPart={this.handleActiveBodyPart}
                activeBodyPart={this.state.activeBodyPart}
                zoomOut={this.handleZoomOut}
                diseases={this.state.diseases}
                diseasesEtiology={this.state.diseasesEtiology}
                bodyInformations={this.state.bodyInformations}
                addFilter={this.props.addFilter}
                addToInfoBox={this.props.addToInfoBox}
                setActiveDisease={this.handleActiveDisease}
              />
            </Col>
            <Col md={5}>
              <Row>
                <Col md={12}>
                  <BodyPartBox bodyPart={this.state.activeBodyPart} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <DiseaseBox
                    diseases={this.state.diseases}
                    activeDisease={this.state.activeDisease !== null ? this.state.activeDisease.icdCode : null}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Fragment>
    )
  }
}
