import React, { Fragment, Component } from 'react';
import './App.css';
import Header from './Header/Header';
import Content from './Content';
import * as Properties from './Properties'
import TextToSpeech from './TextToSpeech';
import withAuth from './withAuth';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view: "documents",
    }
  }

  handleViewChange = (view) => {
    this.setState({
      view: view,
    })
  }

  render() {
    return (
      <Fragment>
        <Header
          infoBoxItems={this.state.infoBoxItems}
          changeView={this.handleViewChange}
          view={this.state.view}
          handleRadialSearch={this.handleRadialSearch}
        /* hospitals={this.getHospitals()} */
        />
        <div className="content">
          <Content
            addToInfoBox={this.handleInfoBox}
            view={this.state.view}
            changeView={this.handleViewChange}
          /* hospitals={this.getHospitals()}
          pharmacys={this.getPharmacies()} */
          />
        </div>
        <TextToSpeech />
      </Fragment>
    )
  }
}

export default withAuth(App);
