import React, { Component } from 'react'
import { Map, Marker, Popup, FeatureGroup, LayersControl, ImageOverlay, GeoJSON } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L, { latLngBounds } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster'
import DocumentImage from '../images/Arbeitszimmer_6.png'
import Control from 'react-leaflet-control';
import { Button } from 'react-bootstrap';
import * as Service from '../Service';
import PDF from './Infobox.pdf';


require('react-leaflet-markercluster/dist/styles.min.css');

export default class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: [],
        }
    }

    clickGeoJson = event => {
        var view = event.target.feature.properties.view;
        if (view === "Download") {
            window.open(PDF, "_blank");
            return;
        }
        if (view !== undefined) {
            this.props.changeView(view);
        }
    }
    render() {
        const bounds = latLngBounds([[0, 0], [424, 620]])
        return (
            <Map bounds={bounds} style={{ height: "831px", width: "100%" }} maxZoom={3} minZoom={1} crs={L.CRS.Simple}
                onBaseLayerChange={event => this.handleBaseLayerChange(event)} zoomControl={false}
            >
                <ImageOverlay
                    url={DocumentImage}
                    bounds={bounds}
                />
                <FeatureGroup >
                    <MarkerClusterGroup >
                        {this.state.documents.map((marker, index) => {
                            return (
                                <Marker position={marker.position} key={marker.position + index} title={marker.title} onClick={() => console.log("markerClick")}>
                                    <Popup>
                                    </Popup>
                                </Marker>
                            )
                        })}
                    </MarkerClusterGroup>
                </FeatureGroup>
                <GeoJSON
                    data={Service["arbeitszimmer_geoJson"]}
                    style={feature => { return { opacity: 0, fillOpacity: 0 } }}
                    onEachFeature={(feature, layer) => {
                        layer.on({
                            click: this.clickGeoJson,
                            mouseover: () => layer.setStyle({ opacity: 1, fillOpacity: 0.1 }),
                            mouseout: () => layer.setStyle({ opacity: 0, fillOpacity: 0 })
                        });
                        layer.bindTooltip(feature.properties.name);
                    }}
                />

            </Map>
        )
    }
}