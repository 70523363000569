import React, { Component, Fragment } from 'react';
import { hideMenu } from "react-contextmenu";
import Vis from 'react-graph-vis';
import './contextmenu.css';
import * as Properties from './Properties';
import './vis-network-master/dist/vis-network.css';
import { connect } from "react-redux";
import { addFilter, removeFilter } from "./Redux/Actions";

var tArr = {
    "Einrichtungen": "facilities",
    "Krankheiten": "diseases",
    "Behandlungen": "treatments",
    "Regionen": "regions",
    "Spezialisten": "specialists",
    "Anatomie": "anatomy",
    "Versorgung": "supply",
    "Sprache": "language",
    "Volltextsuche": "full text search",
    "Medikamente": "medicaments",
    "Hilfsmittel": "medical products",
    "Kliniken": "clinics",
    "Praxen": "medical offices",
    "Apotheken": "pharmacies"
};

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            network: null,
            nodes: [],
            edges: [],
            leftClickedNode: null,
            rightClickedNode: null,
            visNodes: null,
            lang: 'de',
        }
    }

    componentDidMount() {
        var nodes = [
            { id: 1, label: 'my \nmedimaps', group: 0, shape: "circle" },
            { id: 2, label: this.trans('Einrichtungen'), group: 1, shape: "circle" },
            { id: 3, label: this.trans('Krankheiten'), group: 2, shape: "circle", code: 'ICD10' },
            { id: "regions", label: this.trans('Regionen'), group: 3, shape: "circle", code: 'regions' },
            { id: 80, label: this.trans('Spezialisten'), group: 4, shape: "circle" },
            { id: 500, label: this.trans('Anatomie'), group: 6, shape: "circle", meshTreeNumber: 'A' },
            { id: 150, label: this.trans('Versorgung'), group: 5, shape: "circle" },
        ];

        var edges = [
            { from: 1, to: 2 },
            { from: 1, to: 3 },
            { from: 1, to: "regions" },
            { from: 1, to: 80 },
            { from: 1, to: 150 },
            { from: 1, to: 500 },
            { from: 3, to: 4 },
            { from: 3, to: 7 },
            { from: 3, to: 14 },
            { from: 3, to: 15 },
            { from: 3, to: 16 },
            { from: 3, to: 17 },
            { from: 3, to: 18 },
            { from: 3, to: 19 },
            { from: 3, to: 20 },
            { from: 4, to: 5 },
            { from: 4, to: 6 },
            { from: 7, to: 8 },
            { from: 7, to: 9 },
            { from: 7, to: 10 },
            { from: 7, to: 11 },
            { from: 7, to: 12 },
            { from: 7, to: 13 },

        ]
        this.setState({
            nodes: nodes,
            edges: edges,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.hospitals.length !== prevProps.hospitals.length) {
            var nodes = this.state.nodes;
            nodes.find(node => node.id === 2).label = this.trans('Einrichtungen') + ' (' + (this.props.hospitals.length + this.props.pharmacys.length) + ')';
            var oldHospitalNode = nodes.find(node => node.id === 181)
            if (oldHospitalNode !== undefined) oldHospitalNode.label = this.trans('Kliniken') + ' (' + this.props.hospitals.length + ')';
            if (this.state.visNodes) {
                this.state.visNodes.update(nodes);
                this.setState({
                    nodes: nodes,
                })
            }
        }
        if (this.props.pharmacys.length !== prevProps.pharmacys.length) {
            nodes = this.state.nodes;
            nodes.find(node => node.id === 2).label = this.trans('Einrichtungen') + ' (' + (this.props.hospitals.length + this.props.pharmacys.length + this.props.doctors.length) + ')';
            var oldPharmacyNode = nodes.find(node => node.id === 183)
            if (oldPharmacyNode !== undefined) oldPharmacyNode.label = this.trans('Apotheken') + ' (' + this.props.pharmacys.length + ')';
            if (this.state.visNodes) {
                this.state.visNodes.update(nodes);
                this.setState({
                    nodes: nodes,
                })
            }
        }
        if (this.props.doctors.length !== prevProps.doctors.length) {
            nodes = this.state.nodes;
            nodes.find(node => node.id === 2).label = this.trans('Einrichtungen') + ' (' + (this.props.hospitals.length + this.props.pharmacys.length + this.props.doctors.length) + ')';
            var oldPharmacyNode = nodes.find(node => node.id === 182)
            if (oldPharmacyNode !== undefined) oldPharmacyNode.label = this.trans('Praxen') + ' (' + this.props.doctors.length + ')';
            if (this.state.visNodes) {
                this.state.visNodes.update(nodes);
                this.setState({
                    nodes: nodes,
                })
            }
        }
    }

    trans = (str) => {
        if (!localStorage.getItem("lang") || localStorage.getItem("lang") === 'de') {
            return str;
        }
        return tArr[str];
    }

    getLang = () => {
        if (!localStorage.getItem("lang")) return 'de'
        return localStorage.getItem("lang")
    }

    handleLeftClick = (visInfo) => {
        hideMenu()
        this.resetConnections();
        if (visInfo.nodes.length === 0) {
            this.handleClickWithoutNodes();
        } else {
            this.handleCluster(visInfo.nodes[0])
        }
    }

    handleRightClick = (visInfo) => {
        hideMenu()
        this.resetConnections();
        if (!this.state.rightClickedNode) {
            this.handleClickWithoutNodes();
        } else {
            this.handleCluster(this.state.rightClickedNode)
        }
    }

    handleClickWithoutNodes = () => {
        this.resetConnections();
    }

    resetConnections = () => {
        var allNodes = this.state.nodes;
        allNodes.forEach(node => {
            node.color = undefined;
            if (node.oldLabel !== undefined) {
                node.label = node.oldLabel;
                node.oldLabel = undefined;
            }
            if (node.oldGroup !== undefined) {
                node.group = node.oldGroup;
                node.oldGroup = undefined;
            }
            node.levelOfSeperation = undefined;
            node.inConnectionList = undefined;
        })
        this.setState({
            nodes: allNodes
        }, () => this.state.visNodes.update(allNodes))
    }

    clusterNodes = (nodeId) => {
        var children = []
        this.getAllChildren(nodeId, children);
        var nodes = this.state.nodes.filter(node => !children.includes(node.id))
        var edges = this.state.edges.filter(edge => !children.includes(edge.to))
        this.setState({
            nodes: nodes,
            edges: edges
        })
    }

    openCluster = (nodeId) => {
        var nodes = [
            { id: 4, label: 'Kopf', group: 2 },
            { id: 5, label: 'Auge', group: 2 },
            { id: 6, label: 'Ohr', group: 2 },
            { id: 7, label: 'Ganzer Körper', group: 2 },
            { id: 8, label: 'Haut, Haare und Nägel', group: 2 },
            { id: 9, label: 'Muskeln, Knochen und Gelenke', group: 2 },
            { id: 10, label: 'Tumoren', group: 2 },
            { id: 11, label: 'Verletzungen und Vergiftungen', group: 2 },
            { id: 12, label: 'Blut und Immunsystem', group: 2 },
            { id: 13, label: 'Nervensystem', group: 2 },
            { id: 14, label: 'Atemwege', group: 2 },
            { id: 15, label: 'Harnwege und Geschlechtsorgan', group: 2 },
            { id: 16, label: 'Herz und Gefäße', group: 2 },
            { id: 17, label: 'Schwangerschaft und Geburt', group: 2 },
            { id: 18, label: 'Verdauungssystem', group: 2 },
            { id: 19, label: 'Psyche und Verhalten', group: 2 },
            { id: 20, label: 'Sonstiges', group: 2 },
            { id: 22, label: 'Deutschland', group: 3 },
            { id: 23, label: 'Belgien', group: 3 },
            { id: 24, label: 'Frankreich', group: 3 },
            { id: 25, label: 'Österreich', group: 3 },
            { id: 26, label: 'Schweiz', group: 3 },
            { id: 27, label: 'Spanien', group: 3 },
            { id: 28, label: 'Niederlande', group: 3 },
            { id: 29, label: 'Luxembourg', group: 3 },
            { id: 30, label: 'Saarland', group: 3 },
            { id: 31, label: 'Rheinland-Pfalz', group: 3 },
            { id: 32, label: 'Hessen', group: 3 },
            { id: 33, label: 'Baden-Württemberg', group: 3 },
            { id: 34, label: 'Berlin', group: 3 },
            { id: 35, label: 'Hamburg', group: 3 },
            { id: 36, label: 'Bremen', group: 3 },
            { id: 37, label: 'Bayern', group: 3 },
            { id: 38, label: 'Nordrhein-Westfalen', group: 3 },
            { id: 39, label: 'Sachsen', group: 3 },
            { id: 40, label: 'Sachsen-Anhalt', group: 3 },
            { id: 41, label: 'Niedersachsen', group: 3 },
            { id: 42, label: 'Brandenburg', group: 3 },
            { id: 43, label: 'Mecklenburg-Vorpommern', group: 3 },
            { id: 44, label: 'Schleswig-Holstein', group: 3 },
            { id: 45, label: 'Thüringen', group: 3 },
            { id: 151, label: this.trans('Medikamente'), group: 5 },
            { id: 152, label: this.trans('Hilfsmittel'), group: 5 },
            { id: 201, label: this.trans('Behandlungen'), group: 5 },
            { id: 181, label: this.trans('Kliniken') + ' (' + this.props.hospitals.length + ')', group: 1, shape: "circle", color: '#f20d0d' },
            { id: 182, label: this.trans('Praxen') + ' (' + this.props.doctors.length + ')', group: 1, shape: "circle", color: '#ff5c33' },
            { id: 183, label: this.trans('Apotheken') + ' (' + this.props.pharmacys.length + ')', group: 1, shape: "circle", color: '#7d3636' },
            { id: 1400, label: 'Hauts-de-France', group: 3 },
            { id: 1401, label: 'Normandie', group: 3 },
            { id: 1402, label: 'Île-de-France', group: 3 },
            { id: 1403, label: 'Grand Est', group: 3 },
            { id: 1404, label: 'Bretagne', group: 3 },
            { id: 1405, label: 'Pays de la Loire', group: 3 },
            { id: 1406, label: 'Centre-Val de Loire', group: 3 },
            { id: 1407, label: 'Bourgogne-Franche-Comté', group: 3 },
            { id: 1408, label: 'Nouvelle-Aquitaine', group: 3 },
            { id: 1409, label: 'Auvergne-Rhône-Alpes', group: 3 },
            { id: 1410, label: 'Okzitanien', group: 3 },
            { id: 1411, label: "Provence-Alpes-Côte d'Azur", group: 3 },
            { id: 1420, label: "Ardennes", group: 3 },
            { id: 1421, label: "Marne", group: 3 },
            { id: 1422, label: "Aube", group: 3 },
            { id: 1423, label: "Meuse", group: 3 },
            { id: 1424, label: "Haute-Marne", group: 3 },
            { id: 1425, label: "Meurthe-et-Moselle", group: 3 },
            { id: 1426, label: "Moselle", group: 3 },
            { id: 1427, label: "Vosges", group: 3 },
            { id: 1428, label: "Bas-Rhin", group: 3 },
            { id: 1429, label: "Haut-Rhin", group: 3 },
            { id: 1500, label: "Spezialisten \nA-G", group: 4 },
            { id: 1501, label: "Spezialisten \nH-M", group: 4 },
            { id: 1502, label: "Spezialisten \nN-Z", group: 4 },
            /* A-G */
            { id: 1510, label: "Allergologie", group: 4 },
            { id: 1511, label: "Allgemeine (innere) Medizin", group: 4 },
            { id: 1512, label: "Allgemeine Hämatologie", group: 4 },
            { id: 1513, label: "Anästhesiologie", group: 4 },
            { id: 1514, label: "Ansteckende Krankheiten", group: 4 },
            { id: 1515, label: "Arbeitsmedizin", group: 4 },
            { id: 1516, label: "Augenheilkunde", group: 4 },
            { id: 1517, label: "Biologische Hämatologie", group: 4 },
            { id: 1518, label: "Chirurgie", group: 4 },
            { id: 1519, label: "Diagnostische Radiologie", group: 4 },
            { id: 1520, label: "Endokrinologie", group: 4 },
            { id: 1521, label: "Gastroenterologie", group: 4 },
            { id: 1522, label: "Gastroenterologische Chirurgie", group: 4 },
            { id: 1523, label: "Geburtshilfe und Frauenheilkunde", group: 4 },
            { id: 1524, label: "Gefäßchirurgie", group: 4 },
            { id: 1525, label: "Geriatrie", group: 4 },
            /* H-M */
            { id: 1540, label: "Hals-Nasen-Ohren-Heilkunde", group: 4 },
            { id: 1541, label: "Haut- und Geschlechtskrankheiten", group: 4 },
            { id: 1542, label: "Humangenetik/Medizinische Genetik", group: 4 },
            { id: 1543, label: "Immunologie", group: 4 },
            { id: 1544, label: "Kardiologie", group: 4 },
            { id: 1545, label: "Kinder- und Jugendpsychiatrie", group: 4 },
            { id: 1546, label: "Kinderchirurgie", group: 4 },
            { id: 1547, label: "Kinderheilkunde", group: 4 },
            { id: 1548, label: "Lungen- und Bronchialheilkunde", group: 4 },
            { id: 1549, label: "Medizinische Biologie", group: 4 },
            { id: 1550, label: "Medizinische Onkologie", group: 4 },
            { id: 1551, label: "Medizinische und chemische Labordiagnostik", group: 4 },
            { id: 1552, label: "Mikrobiologie und Infektionsepidemiologie", group: 4 },
            { id: 1553, label: "Mund-Kiefer-Gesichtschirurgie", group: 4 },
            /* N - Z */
            { id: 1570, label: "Neurochirurgie", group: 4 },
            { id: 1571, label: "Neurologie", group: 4 },
            { id: 1572, label: "Neuropsychiatrie", group: 4 },
            { id: 1573, label: "Nierenkrankheiten", group: 4 },
            { id: 1574, label: "Nuklearmedizin", group: 4 },
            { id: 1575, label: "Orthopädie", group: 4 },
            { id: 1576, label: "Pathologie", group: 4 },
            { id: 1577, label: "Pharmakologie", group: 4 },
            { id: 1578, label: "Physiotherapie", group: 4 },
            { id: 1579, label: "Plastische Chirurgie", group: 4 },
            { id: 1580, label: "Psychiatrie", group: 4 },
            { id: 1581, label: "Radiologie", group: 4 },
            { id: 1582, label: "Rheumatologie", group: 4 },
            { id: 1583, label: "Strahlentherapie", group: 4 },
            { id: 1584, label: "Thoraxchirurgie", group: 4 },
            { id: 1585, label: "Urologie", group: 4 },
            { id: 1586, label: "Zahn-, Mund-, Kiefer- und Gesichtschirurgie", group: 4 },

        ]
        var childEdges = {
            2: [
                { from: 2, to: 181 },
                { from: 2, to: 182 },
                { from: 2, to: 183 },
            ],
            3: [
                { from: 3, to: 4 },
                { from: 3, to: 7 },
                { from: 3, to: 14 },
                { from: 3, to: 15 },
                { from: 3, to: 16 },
                { from: 3, to: 17 },
                { from: 3, to: 18 },
                { from: 3, to: 19 },
                { from: 3, to: 20 },
            ],
            4: [
                { from: 4, to: 5 },
                { from: 4, to: 6 },
            ],
            7: [
                { from: 7, to: 8 },
                { from: 7, to: 9 },
                { from: 7, to: 10 },
                { from: 7, to: 11 },
                { from: 7, to: 12 },
                { from: 7, to: 13 },
            ],
            21: [
                { from: 21, to: 22 },
                { from: 21, to: 23 },
                { from: 21, to: 24 },
                { from: 21, to: 25 },
                { from: 21, to: 26 },
                { from: 21, to: 27 },
                { from: 21, to: 28 },
                { from: 21, to: 29 },
            ],
            22: [
                { from: 22, to: 30 },
                { from: 22, to: 31 },
                { from: 22, to: 32 },
                { from: 22, to: 33 },
                { from: 22, to: 34 },
                { from: 22, to: 35 },
                { from: 22, to: 36 },
                { from: 22, to: 37 },
                { from: 22, to: 38 },
                { from: 22, to: 39 },
                { from: 22, to: 40 },
                { from: 22, to: 41 },
                { from: 22, to: 42 },
                { from: 22, to: 43 },
                { from: 22, to: 44 },
                { from: 22, to: 45 },
            ],
            150: [
                { from: 150, to: 151 },
                { from: 150, to: 152 },
                { from: 150, to: 201 },
            ],
            24: [
                { from: 24, to: 1400 },
                { from: 24, to: 1401 },
                { from: 24, to: 1402 },
                { from: 24, to: 1403 },
                { from: 24, to: 1404 },
                { from: 24, to: 1405 },
                { from: 24, to: 1406 },
                { from: 24, to: 1407 },
                { from: 24, to: 1408 },
                { from: 24, to: 1409 },
                { from: 24, to: 1410 },
                { from: 24, to: 1411 },
            ],
            1403: [
                { from: 1403, to: 1420 },
                { from: 1403, to: 1421 },
                { from: 1403, to: 1422 },
                { from: 1403, to: 1423 },
                { from: 1403, to: 1424 },
                { from: 1403, to: 1425 },
                { from: 1403, to: 1426 },
                { from: 1403, to: 1427 },
                { from: 1403, to: 1428 },
                { from: 1403, to: 1429 },
            ],
            80: [
                { from: 80, to: 1500 },
                { from: 80, to: 1501 },
                { from: 80, to: 1502 },
            ],
            1500: [
                { from: 1500, to: 1510 },
                { from: 1500, to: 1511 },
                { from: 1500, to: 1512 },
                { from: 1500, to: 1513 },
                { from: 1500, to: 1514 },
                { from: 1500, to: 1515 },
                { from: 1500, to: 1516 },
                { from: 1500, to: 1517 },
                { from: 1500, to: 1518 },
                { from: 1500, to: 1519 },
                { from: 1500, to: 1520 },
                { from: 1500, to: 1521 },
                { from: 1500, to: 1522 },
                { from: 1500, to: 1523 },
                { from: 1500, to: 1524 },
                { from: 1500, to: 1525 },
            ],
            1501: [
                { from: 1501, to: 1540 },
                { from: 1501, to: 1541 },
                { from: 1501, to: 1542 },
                { from: 1501, to: 1543 },
                { from: 1501, to: 1544 },
                { from: 1501, to: 1545 },
                { from: 1501, to: 1546 },
                { from: 1501, to: 1547 },
                { from: 1501, to: 1548 },
                { from: 1501, to: 1549 },
                { from: 1501, to: 1550 },
                { from: 1501, to: 1551 },
                { from: 1501, to: 1552 },
                { from: 1501, to: 1553 },
            ],
            1502: [
                { from: 1502, to: 1570 },
                { from: 1502, to: 1571 },
                { from: 1502, to: 1572 },
                { from: 1502, to: 1573 },
                { from: 1502, to: 1574 },
                { from: 1502, to: 1575 },
                { from: 1502, to: 1576 },
                { from: 1502, to: 1577 },
                { from: 1502, to: 1578 },
                { from: 1502, to: 1579 },
                { from: 1502, to: 1580 },
                { from: 1502, to: 1581 },
                { from: 1502, to: 1582 },
                { from: 1502, to: 1583 },
                { from: 1502, to: 1584 },
                { from: 1502, to: 1585 },
                { from: 1502, to: 1586 },
            ]
        }
        var newEdges = childEdges[nodeId]
        if (newEdges === undefined) {
            return;
        }
        var newNodes = [];
        newEdges.forEach(edge => {
            if (this.state.nodes.find(node => node.id === edge.to) === undefined) {
                if (edge.to === 30) {
                    var saarlandNode = nodes.find(node => node.id === "31fd8926-5adf-49e2-a4bf-b4df89eea303")
                    import('./images/saarland.png')
                        .then(picture => {
                            var newNode = saarlandNode;
                            newNode.label = " ";
                            newNode.shape = "image";
                            newNode.image = picture.default;
                            newNode.size = 25;
                            newNode.filterLabel = "Saarland";
                            this.setState({
                                nodes: this.state.nodes.concat(newNode),
                            })
                        })
                        .catch(() => false)

                } else if (edge.to === 34) {
                    var berlinNode = nodes.find(node => node.id === 34)
                    import('./images/Berlin.PNG')
                        .then(picture => {
                            var newNode = berlinNode;
                            newNode.label = " ";
                            newNode.shape = "image";
                            newNode.image = picture.default;
                            newNode.size = 25;
                            newNode.filterLabel = "Berlin";
                            this.setState({
                                nodes: this.state.nodes.concat(newNode),
                            })
                        })
                        .catch(() => false)
                } else if (edge.to === 39) {
                    var sachsenNode = nodes.find(node => node.id === 39)
                    import('./images/Sachsen.PNG')
                        .then(picture => {
                            var newNode = sachsenNode;
                            newNode.label = " ";
                            newNode.shape = "image";
                            newNode.image = picture.default;
                            newNode.size = 25;
                            newNode.filterLabel = "Sachsen";
                            this.setState({
                                nodes: this.state.nodes.concat(newNode),
                            })
                        })
                        .catch(() => false)
                } else {
                    newNodes.push(nodes.find(node => node.id === edge.to))
                }
            }
        })

        newNodes = this.state.nodes.concat(newNodes);
        newEdges = this.state.edges.concat(newEdges);

        this.setState({
            nodes: newNodes,
            edges: newEdges
        })
        //var regionImgArr = [ {30: "./images/Saarland.png" } ]
        //console.log(regionImgArr[0])
        //regionImgArr.forEach(function (r) {console.log(r); console.log(r[0]);/*this.updateRegionIcon(id, url); */})
    }



    openClusterFromService = (nodeId, code, group) => {
        var newNodes = [];
        var newEdges = [];
        var newNode;
        if (group === 6) {
            const API_URL = Properties.service_url + "/medimaps-rest/webapi/mesh/v1/tree";
            fetch(API_URL + "/" + code)
                .then(res => res.json())
                .then(meshTree => {
                    var shapeDef = "box";
                    var imageDef = "";
                    meshTree.forEach(meshEntry => {

                        var theLabel = this.getLang() == 'de' ? meshEntry.name_ger : meshEntry.name_en
                        if (meshEntry.name_ger === 'Nase') {
                            console.log('Nase : ' + meshEntry.mesh_tree_number);
                        }
                        if (meshEntry.name_ger === 'Atmungssystem') {
                            console.log('Atmungssystem : ' + meshEntry.mesh_tree_number);
                        }

                        if (meshEntry.mesh_tree_number === 'A04.531' ||
                            meshEntry.mesh_tree_number === 'A01.456.505.733' ||
                            meshEntry.mesh_tree_number === 'A09.531') {
                            import('./images/Nase.png')
                                .then(picture => {
                                    newNode = {
                                        id: meshEntry.mesh_tree_number,
                                        label: "",
                                        title: theLabel,
                                        group: 6,
                                        meshTreeNumber: meshEntry.mesh_tree_number,
                                        image: picture.default,
                                        shape: "image",
                                        size: 25,
                                        filterLabel: theLabel,
                                        color: { background: '#000000' }
                                    }

                                    var newEdge = { from: nodeId, to: newNode.id };
                                    this.setState({
                                        nodes: this.state.nodes.concat(newNode),
                                        edges: this.state.edges.concat(newEdge),
                                    })
                                })
                                .catch(() => false)

                        } else {
                            if (meshEntry.mesh_tree_number === 'A04') {
                                import('./images/Atmungssystem.png')
                                    .then(picture => {
                                        newNode = {
                                            id: meshEntry.mesh_tree_number,
                                            label: "",
                                            title: theLabel,
                                            group: 6,
                                            meshTreeNumber: meshEntry.mesh_tree_number,
                                            image: picture.default,
                                            shape: "image",
                                            size: 25,
                                            filterLabel: theLabel,
                                            color: { background: '#000000' }
                                        }
                                        var newEdge = { from: nodeId, to: newNode.id };
                                        this.setState({
                                            nodes: this.state.nodes.concat(newNode),
                                            edges: this.state.edges.concat(newEdge),
                                        })
                                    })
                                    .catch(() => false)
                            } else {
                                shapeDef = "box";
                                imageDef = "";
                                newNode = {
                                    id: meshEntry.mesh_tree_number,
                                    label: this.getLang() == 'de' ? meshEntry.name_ger : meshEntry.name_en,
                                    title: this.getLang() == 'de' ? meshEntry.name_ger : meshEntry.name_en,
                                    group: 6,
                                    meshTreeNumber: meshEntry.mesh_tree_number,
                                    image: imageDef,
                                    shape: shapeDef,
                                    size: 25,
                                    filterLabel: this.getLang() == 'de' ? meshEntry.name_ger : meshEntry.name_en
                                }

                                var newEdge = { from: nodeId, to: newNode.id };
                                this.setState({
                                    nodes: this.state.nodes.concat(newNode),
                                    edges: this.state.edges.concat(newEdge),
                                })
                            }
                        }
                    })

                });
        }
        if (group === 2) {
            const API_URL = Properties.service_url + "/medimaps-rest/webapi/icd/v1/tree";
            fetch(API_URL + "/" + code)
                .then(res => res.json())
                .then(icds => {
                    icds.forEach(icd => {
                        var icd_label = this.getLang() === 'en' ? icd.label_en : icd.label;
                        if (!icd_label) icd_label = icd.label
                        var newNode = {
                            id: icd.guid,
                            label: icd_label.length > 30 ? icd_label.substring(0, 30) + '...' : icd_label,
                            title: icd_label,
                            group: 2,
                            code: icd.code
                        };
                        var newEdge = { from: nodeId, to: newNode.id };
                        newNodes.push(newNode);
                        newEdges.push(newEdge);
                    })
                    this.setState({
                        nodes: this.state.nodes.concat(newNodes),
                        edges: this.state.edges.concat(newEdges),
                    })
                });
        }
        if (group === 3) {
            const API_URL = Properties.service_url + "/medimaps-rest/webapi/organization/v1/getFState";
            fetch(API_URL + "/" + code)
                .then(res => res.json())
                .then(fStates => {
                    fStates.forEach((fState, index) => {
                        var newNode = {
                            id: fState.id,
                            label: fState.label,
                            title: fState.label,
                            group: 3,
                            code: fState.label
                        };
                        var newEdge = { from: nodeId, to: newNode.id };
                        newNodes.push(newNode);
                        newEdges.push(newEdge);
                    })
                    this.setState({
                        nodes: this.state.nodes.concat(newNodes),
                        edges: this.state.edges.concat(newEdges),
                    })
                });
        }
    }

    getChildsOfNode = (nodeId) => {
        var parentNode = this.state.nodes.find(node => node.id === nodeId)
        var children = this.state.network.getConnectedNodes(nodeId, "to");
        children = children.filter(childNodeId => {
            var childNode = this.state.nodes.find(node => node.id === childNodeId);
            return childNode.group === parentNode.group
        })
        return children
    }

    getAllChildren = (nodeId, children) => {
        var localChildren = this.getChildsOfNode(nodeId);
        localChildren.forEach(child => {
            children.push(child)
            this.getAllChildren(child, children)
        })
        return localChildren;
    }

    handleCluster = (nodeId) => {
        if (this.getChildsOfNode(nodeId).length === 0) {
            var node = this.state.nodes.find(node => node.id === nodeId);
            if (node.group === 6) {
                this.openClusterFromService(nodeId, node.meshTreeNumber, 6)
            } else if (node.group === 2) {
                this.openClusterFromService(nodeId, node.code, 2)
            } else if (node.group === 3) {
                this.openClusterFromService(nodeId, node.id, 3)
            } else {
                this.openCluster(nodeId)
            }
        } else {
            this.clusterNodes(nodeId)
        }
        return;
    }

    handleShowConnections = () => {

        var nodeId = this.state.leftClickedNode
        var degrees = 2;
        var allNodes = this.state.nodes;
        var allEdges = this.state.edges;

        // we clear the level of seperation in all nodes.
        this.clearLevelOfSeperation(allNodes);

        // we will now start to collect all the connected nodes we want to highlight.
        var connectedNodes = [nodeId];

        // we can store them into levels of seperation and we could then later use this to define a color per level
        // any data can be added to a node, this is just stored in the nodeObject.
        this.storeLevelOfSeperation(connectedNodes, 0, allNodes);
        for (var i = 1; i < degrees + 1; i++) {
            this.appendConnectedNodes(connectedNodes, allEdges);
            this.storeLevelOfSeperation(connectedNodes, i, allNodes);
        }

        allNodes.forEach(node => {
            if (node.inConnectionList) {
                if (node.levelOfSeperation !== undefined && node.levelOfSeperation >= 2) {
                    node.color = { background: 'rgba(150,150,150,0.75)', border: 'rgba(150,150,150,0.75)' };
                    node.oldGroup = node.group;
                    node.group = undefined;
                } else {
                    node.color = undefined;
                    if (node.oldGroup !== undefined) {
                        node.group = node.oldGroup;
                        node.oldGroup = undefined;
                    }
                }
                if (node.oldLabel !== undefined) {
                    node.label = node.oldLabel;
                    node.oldLabel = undefined;
                }

            } else {
                node.color = { background: 'rgba(200,200,200,0.5)', border: 'rgba(200,200,200,0.5)' };
                if (node.oldLabel === undefined) {
                    node.oldLabel = node.label;
                    //Leerzeichen als Label ist wichtig, da sonst nicht gelöscht wird. TODO Fix suchen
                    node.label = " ";
                }
                if (node.group !== undefined) {
                    node.oldGroup = node.group;
                    node.group = undefined;
                }
            }
        })
        this.setState({
            nodes: allNodes
        }, () => this.state.visNodes.update(allNodes))
    }

    /**
    * update the allNodes object with the level of seperation.
    * Arrays are passed by reference, we do not need to return them because we are working in the same object.
    */
    storeLevelOfSeperation = (connectedNodes, level, allNodes) => {
        connectedNodes.forEach(nodeId => {
            var node = allNodes.find(node => node.id === nodeId)
            if (node.levelOfSeperation === undefined) {
                node.levelOfSeperation = level;
            }
            node.inConnectionList = true;
        })
    }

    clearLevelOfSeperation = (allNodes) => {
        allNodes.forEach(node => {
            node.levelOfSeperation = undefined;
            node.inConnectionList = undefined;
        })
    }

    /**
     * Add the connected nodes to the list of nodes we already have
     *
     *
     */
    appendConnectedNodes = (sourceNodes, allEdges) => {
        var tempSourceNodes = [];
        // first we make a copy of the nodes so we do not extend the array we loop over.
        for (var i = 0; i < sourceNodes.length; i++) {
            tempSourceNodes.push(sourceNodes[i])
        }

        for (i = 0; i < tempSourceNodes.length; i++) {
            var nodeId = tempSourceNodes[i];
            if (sourceNodes.indexOf(nodeId) === -1) {
                sourceNodes.push(nodeId);
            }
            this.addUnique(this.getConnectedNodes(nodeId, allEdges), sourceNodes);
        }
        tempSourceNodes = null;
    }

    /**
     * Join two arrays without duplicates
     * @param fromArray
     * @param toArray
     */
    addUnique = (fromArray, toArray) => {
        for (var i = 0; i < fromArray.length; i++) {
            if (toArray.indexOf(fromArray[i]) === -1) {
                toArray.push(fromArray[i]);
            }
        }
    }

    /**
     * Get a list of nodes that are connected to the supplied nodeId with edges.
     * @param nodeId
     * @returns {Array}
     */
    getConnectedNodes = (nodeId, allEdges) => {
        var edgesArray = allEdges;
        var connectedNodes = [];

        for (var i = 0; i < edgesArray.length; i++) {
            var edge = edgesArray[i];
            if (edge.to === nodeId) {
                connectedNodes.push(edge.from);
            }
            else if (edge.from === nodeId) {
                connectedNodes.push(edge.to)
            }
        }
        return connectedNodes;
    }

    handleAddFilter = (nodeId) => {
        var fullNode = this.state.nodes.find(node => node.id === nodeId);
        if (!fullNode) return;
        fullNode['shapeProperties'] = { useBorderWithImage: true }
        fullNode['borderWidth'] = 8
        //fullNode['color'] = { border: '#000000', background: "#000000" }
        this.state.visNodes.update(fullNode)
        if (fullNode.group === 2) {
            fullNode.type = "ICD"
        }
        if (fullNode.group === 3) {
            fullNode.type = "fState"
        }
        this.props.addFilter(fullNode)

    }

    handleRemoveilter = (filter) => {
        /*
        console.log("Graph.js :: handleAddFilter")
        var fullNode = this.state.nodes.find(node => node.id === nodeId);
        console.log("handleAddFilter" + fullNode)
        fullNode['filterLabel'] = fullNode.title
        console.log(fullNode)
        console.log("leave handleAddFilter" + fullNode)

        fullNode['shapeProperties'] = { useBorderWithImage:true}
        fullNode['borderWidth'] = 5
        fullNode['color'] = { border: '#000000', background: "#000000" }
        this.state.visNodes.update(fullNode)

        this.props.addToFilter(fullNode)
        */

    }

    render() {

        var graph = {
            nodes: this.state.nodes,
            edges: this.state.edges,
        }
        // create a network

        var options = {

            nodes: {
                size: 50,
                font: {
                    size: 15,
                    face: "Tahoma",
                },
                borderWidth: 2,
                widthConstraint: 100,
                shape: "box",
                margin: {
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                },
                labelHighlightBold: false,
            },
            edges: {
                color: {
                    inherit: "from"
                },
                width: 0.2,
                arrows: {
                    to: {
                        enabled: false,
                    }
                },
                smooth: true,
            },
            physics: {
                barnesHut: {
                    gravitationalConstant: -70000,
                    centralGravity: 0.3,
                    springLength: 50,
                    springConstant: 0.08,
                    damping: 0.09,
                },
            },
            groups: {
                useDefaultGroups: false,
                0: {
                    color: {
                        border: "#888",
                        background: "#bbbec3",
                        highlight: {
                            border: "#888",
                            background: "#bbbec3"
                        },
                        hover: {
                            border: "#888",
                            background: "#bbbec3"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
                1: {
                    color: {
                        border: "#f20d0d",
                        background: "#f53d3d",
                        highlight: {
                            border: "#f20d0d",
                            background: "#f53d3d"
                        },
                        hover: {
                            border: "#f20d0d",
                            background: "#f53d3d"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
                2: {
                    color: {
                        border: "#e6e600",
                        background: "#ffff00",
                        highlight: {
                            border: "#e6e600",
                            background: "#ffff00"
                        },
                        hover: {
                            border: "#e6e600",
                            background: "#ffff00"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
                3: {
                    color: {
                        border: "#739900",
                        background: "#99cc00",
                        highlight: {
                            border: "#739900",
                            background: "#99cc00"
                        },
                        hover: {
                            border: "#739900",
                            background: "#99cc00"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
                4: {
                    color: {
                        border: "#33ff9c",
                        background: "#66ffb5",
                        highlight: {
                            border: "#33ff9c",
                            background: "#66ffb5"
                        },
                        hover: {
                            border: "#33ff9c",
                            background: "#66ffb5"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
                5: {
                    color: {
                        border: "#b38600",
                        background: "#e6ac00",
                        highlight: {
                            border: "#b38600",
                            background: "#e6ac00"
                        },
                        hover: {
                            border: "#b38600",
                            background: "#e6ac00"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
                6: {
                    color: {
                        border: "#1a75ff",
                        //border: "#000000",
                        background: "#4d94ff",
                        //background: "#000000",
                        highlight: {
                            border: "#1a75ff",
                            background: "#4d94ff"
                        },
                        hover: {
                            border: "#1a75ff",
                            background: "#4d94ff"
                        }
                    },
                    font: {
                        color: '#000',
                        size: 16,
                    }
                },
            }
        };

        var events = {
            oncontext: (visInfo => {
                visInfo.event.preventDefault();
                hideMenu()
                var node = this.state.network.getNodeAt(visInfo.pointer.DOM);
                this.handleAddFilter(node)
                //this.handleLeftClick(visInfo)
            }),
            click: (visInfo => {
                hideMenu()
                var node = this.state.network.getNodeAt(visInfo.pointer.DOM);
                this.setState({
                    rightClickedNode: node,
                    leftClickedNode: null
                })
                this.handleRightClick(visInfo)

            })
        }


        var rightClickedNode = this.state.nodes.find(node => node.id === this.state.rightClickedNode);
        var leftClickedNode = this.state.nodes.find(node => node.id === this.state.leftClickedNode);
        return (
            <Fragment>
                {/* {this.state.leftClickedNode ?
                    <ContextMenu id="graph_rightclick">
                        {/* <MenuItem onClick={this.handleShowConnections}>
                            Verbindungen hervorheben
                    </MenuItem> }
                        {!(leftClickedNode.group === 3 || leftClickedNode.id === 1) &&
                            <MenuItem onClick={() => this.props.addToInfoBox(leftClickedNode.label)}>
                                Merken
                    </MenuItem>
                        }
                        {/* <MenuItem divider /> }
                        {((leftClickedNode.group === 6 || leftClickedNode.group === 2 || leftClickedNode.group === 3)) &&
                            <MenuItem onClick={() => this.handleAddFilter(this.state.leftClickedNode)}>
                                Filtern
                    </MenuItem>
                        }
                    </ContextMenu>
                    :
                    <ContextMenu id="graph_rightclick">
                        <MenuItem onClick={() => {
                            this.props.changeView("map")
                        }}
                        >
                            zur Karte
                    </MenuItem>
                        <MenuItem onClick={() => {
                            this.props.changeView("bodySchema");
                        }}>
                            zum Körper
                        </MenuItem>
                        <MenuItem onClick={() => {
                            this.props.changeView("documents");
                        }}>
                            zu Dokumenten
</MenuItem>
                    </ContextMenu>
                } */}
                {this.state.nodes.length > 0 &&
                    //<ContextMenuTrigger id="graph_rightclick" holdToDisplay={2000}>
                    <Vis
                        graph={graph}
                        options={options}
                        events={events}
                        getNetwork={network => this.setState({ network: network })}
                        style={{ width: "100vw", height: "calc(100vh - 56px)" }}
                        getNodes={nodes => this.setState({ visNodes: nodes })}
                    />
                    //</ContextMenuTrigger>
                }
            </Fragment >
        )
    }
}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
        filter: store.FilterReducer.filter,
        hospitals: store.HospitalReducer.hospitals,
        pharmacys: store.PharmacyReducer.pharmacys,
        doctors: store.DoctorReducer.doctors,
    }),
    { addFilter, removeFilter }
)(Graph);

