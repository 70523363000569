import React, { Component, Fragment, createRef } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import * as Properties from '../Properties'
import { faFilter, faBookmark, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { addBookmark, addFilter, readText } from "../Redux/Actions";

class DiseaseBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            synonyms: [],
            refs: [],
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.icdCode !== prevProps.icdCode) {
            this.loadData();
        }
        if (this.props.activeDisease !== prevProps.activeDisease) {
            var diseases = new Map();
            this.props.diseases.forEach(disease => diseases.set(disease.icdCode, disease));
            diseases = Array.from(diseases.values());
            diseases.sort((a, b) => a.icdCode.localeCompare(b.icdCode));
            this.state.refs[diseases.findIndex(element => element.icdCode === this.props.activeDisease)].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        if (this.props.diseases !== prevProps.diseases) {
            var diseases = new Map();
            this.props.diseases.forEach(disease => diseases.set(disease.icdCode, disease));
            diseases = Array.from(diseases.values());
            diseases.sort((a, b) => a.icdCode.localeCompare(b.icdCode));
            var refs = diseases.map(disease => createRef())
            this.setState({
                refs: refs,
            })
        }
    }

    loadData = () => {
        const API_URL = Properties.service_url + "/medimaps-rest/webapi/alpha/v1/synonyms";
        fetch(API_URL + "/" + this.props.icdCode)
            .then(res => res.json())
            .then(response => {
                this.setState({
                    synonyms: response,
                })
            })
    }

    addFilter = (disease) => {
        disease.label = disease.title;
        disease.type = "ICD";
        this.props.addFilter(disease);
    }

    render() {
        var diseases = new Map();
        this.props.diseases.forEach(disease => diseases.set(disease.icdCode, disease));
        diseases = Array.from(diseases.values());
        diseases.sort((a, b) => a.icdCode.localeCompare(b.icdCode));

        return (
            <Fragment>
                <div className="card infoBox">
                    <div className="card card-header">
                        <Col md={12}>
                            Krankheiten
                        </Col>
                    </div>
                    <div className="card card-body">
                        <ul className="list-group list-group-flush">
                            {diseases.map((disease, index) => {
                                return (
                                    <li ref={this.state.refs[index]} className={"list-group-item" + (this.props.activeDisease === disease.icdCode ? " list-group-item-warning" : "")} key={disease.icdCode} >
                                        <Row key={disease.code} >
                                            <Col md={2}>
                                                {disease.icdCode}
                                            </Col>
                                            <Col md={7}>
                                                {disease.title}
                                            </Col>
                                            <Col md={3}>
                                                <Button className="btn btn-sm btn-secondary mr-1" title="Filtern" onClick={() => this.addFilter(disease)}>
                                                    <FontAwesomeIcon icon={faFilter} />
                                                </Button>
                                                <Button className="btn btn-sm btn-secondary mr-1" title="Zur Infobox hinzufügen" onClick={() => this.props.addBookmark(disease)}>
                                                    <FontAwesomeIcon icon={faBookmark} />
                                                </Button>
                                                <Button className="btn btn-sm btn-secondary" title="Vorlesen" onClick={() => this.props.readText(disease.title)}>
                                                    <FontAwesomeIcon icon={faVolumeUp} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
    }),
    { addBookmark, addFilter, readText }
)(DiseaseBox);
