
export const CLAIM_NAME = {

}

export const URLS = {
  DASHBOARD: "/dashboard",
  MESH: "/mesh",
  LAYTERM: "/layTerm",
  NEW: "/new",
}

export const DATE_FORMAT = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
}

export const DAY_MS = 86400000

export const SERVICEHOST = 'https://medimaps.org';
export const SERVICEPATH = '/medimaps-rest/webapi';

export const VALIDATION = true;

export const STANDARD_DATES = {
  END_DATE: '2020-12-31',
  START_DATE: '2020-01-01',
}

export const UPDATE_INTERVAL = 86400000 * 365;

