import React, { Component, Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import AuthService from '../AuthService';
import logo from './mediMaps.svg'

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    }


    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (AuthService.loggedIn()) {
      this.props.history.replace('/mesh');
    }
  }

  handleClick() {
    this.setState({
      isModalOpen: true,
    });
  }

  handleClose() {
    this.setState({ isModalOpen: false });
  }

  handleSubmit(values, { setSubmitting, setErrors }) {
    AuthService.login(values)
      .then(res => {

        if (res && res.jwtToken !== undefined) {
          var success = true;
        }
        if (!success) {
          setSubmitting(false);
          setErrors({
            name: 'Falsche Anmeldedaten!'
          });
        } else {
          AuthService.setToken(res.jwtToken)
          this.props.history.replace('/mesh');
          window.location.reload();
        }
      });
  }

  //Richtigen Fetch ergänzen zum Passwort reseten
  handleResetSubmit(values) {
    console.log(values);
    var formBody = [];
    for (var key in values) {
      if (values.hasOwnProperty(key)) {
        var encodeKey = encodeURIComponent(key);
        var encodeValue = encodeURIComponent(values[key]);
        formBody.push(encodeKey + '=' + encodeValue);
      }
    }
    AuthService.fetch('/users/user/1', {
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      method: 'get',
    })
      .then(response => {
        console.log(response);
      })
    this.handleClose();
  }
  render() {
    return (
      <Fragment>
        <div className="auth">
          <div className="auth-container">
            <div className="card">
              <header className="auth-header">
                <h1 className="auth-title">
	    		<img src={logo} alt="medimaps" with="149px" height="100px"/>
	    	</h1>
              </header>
              <div className="auth-content">
                <Formik
                  initialValues={{ name: '', password: '' }}
                  validationSchema={LoginFormValidationSchema}
                  onSubmit={this.handleSubmit}
                >
                  <Form>
                    <ErrorMessage
                      name="name"
                      className="has-error"
                      component="span"
                    />
                    <div className="form-group">
                      <label htmlFor="name"> Benutzername</label>
                      <Field
                        type="text" name="name" className="form-control underlined"
                        placeholder="Benutzername oder Email-Adresse" autoComplete="username"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password"> Passwort </label>
                      <Field
                        type="password" name="password" className="form-control underlined"
                        placeholder="Passwort" autoComplete="current-password"
                      />
                      <ErrorMessage
                        name="password"
                        className="has-error"
                        component="span"
                      />
                    </div>
                    <div className="form-group">
                      <Button type="submit" variant="primary" >Login</Button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.isModalOpen} onHide={this.handleClose}>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={this.handleResetSubmit}
            validationSchema={PwResetFormValidationSchema}
          >
            {props =>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Passwort vergessen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row>
                    <Col md={12}>
                      <div className="form-group">
                        <label className="control-label">Email-Adresse</label>
                        <Field
                          className="form-control boxed"
                          type="text"
                          name="email"
                          label="Email-Adresse"
                        />
                        <ErrorMessage
                          name="email"
                          className="has-error"
                          component="span"
                        />
                      </div>
                    </Col>

                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>Abbrechen</Button>
                  <Button variant="primary" type="submit" disabled={props.isSubmitting}>Neues Passwort anfordern</Button>
                </Modal.Footer>
              </Form>
            }
          </Formik>
        </Modal>
      </Fragment >
    )
  }
}

const LoginFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Bitte Benutzernamen angeben"),
  password: Yup.string()
    .required("Bitte Passwort angeben")
});

const PwResetFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Bitte gültige Email-Adresse angeben")
    .required("Bitte gültige Email-Adresse angeben")
});
