import { combineReducers } from "redux";
import FetchingReducer from './FetchingReducer';
import LanguageReducer from './LanguageReducer';
import FilterReducer from './FilterReducer';
import HospitalReducer from './HospitalReducer';
import PharmacyReducer from './PharmacyReducer';
import DoctorReducer from './DoctorReducer';
import BookmarkReducer from './BookmarkReducer';
import TextToSpeechReducer from './TextToSpeechReducer';

export default combineReducers({
    FetchingReducer, LanguageReducer, FilterReducer,
    HospitalReducer, PharmacyReducer, DoctorReducer, BookmarkReducer, TextToSpeechReducer
})