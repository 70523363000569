import { SET_DOCTORS } from '../ActionsTypes';

const initialState = {
    doctors: [],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_DOCTORS: {
            const { doctors } = action.payload;
            return {
                ...state,
                doctors: doctors,
            }
        }
        default: {
            return state;
        }
    }

}