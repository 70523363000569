import { READ_TEXT } from '../ActionsTypes';

const initialState = {
    text: "",
}

export default (state = initialState, action) => {

    switch (action.type) {
        case READ_TEXT: {
            const { text } = action.payload;
            return {
                ...state,
                text: text,
            }
        }
        default: {
            return state;
        }
    }

}