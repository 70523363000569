import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import { addBookmark } from "../Redux/Actions";

class FacilityPopup extends Component {


    render() {
        console.log(this.props)
        return (
            <Fragment>
                <Row>
                    <Col md={12}>
                        <h5> {this.props.title} </h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        Adresse:
                    </Col>
                    <Col md={8}>
                        {this.props.address}
                    </Col>
                </Row>
                {this.props.department.length > 0 &&
                    <Row>
                        <Col md={4}>
                            Fachrichtungen:
                        </Col>
                    </Row>
                }
                {this.props.department.map((department, index) => {
                    return (
                        <ul>
                            <Row>
                                <Col md={12}>
                                    <li>{department}</li>
                                </Col>
                            </Row>
                        </ul>
                    )
                })}
                <Row>
                    <Col md={12}>
                        <Button variant="secondary" onClick={() => this.props.addBookmark(this.props)}>
                            Zur Infobox
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
    }),
    { addBookmark }
)(FacilityPopup);