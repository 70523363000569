import { Component } from 'react';
import { connect } from "react-redux";
import Speech from 'speak-tts';

class TextToSpeech extends Component {

    constructor(props) {
        super(props);
        this.speech = new Speech();
    }

    componentDidMount() {

    }

    readText = () => {
        this.speech.init({ 'lang': this.props.language }).then((data) => {
            // The "data" object contains the list of available voices and the voice synthesis params
            this.speech.speak({
                text: this.props.text,
            })
        }).catch(e => {
            console.error("An error occured while initializing : ", e)
        })

    }

    render() {
        this.readText();
        return null
    }

}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
        text: store.TextToSpeechReducer.text,
    })
)(TextToSpeech);

