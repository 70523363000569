import { SET_FETCHING } from '../ActionsTypes';

const initialState = {
    fetching: false,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_FETCHING: {
            const { fetching } = action.payload;
            return {
                ...state,
                fetching: fetching,
            }
        }
        default: {
            return state;
        }
    }

}
