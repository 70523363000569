import { SET_HOSPITALS} from '../ActionsTypes';

const initialState = {
    hospitals: [],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_HOSPITALS: {
            const { hospitals } = action.payload;
            return {
                ...state,
                hospitals: hospitals,
            }
        }
        default: {
            return state;
        }
    }

}