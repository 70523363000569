import decode from 'jwt-decode';
import * as Constants from './Constants';
import { setFetching } from './Redux/Actions';
import Store from './Redux/Store';

class AuthService {
  // Initializing important variables
  constructor() {
    this.host = Constants.SERVICEHOST;
    this.path = Constants.SERVICEPATH;
    this.domain = this.host + this.path;
  }

  /* constructor(props) {
    super(props)

    this.state = {
      host: Constants.SERVICEHOST,
      path: Constants.SERVICEPATH,
      domain: Constants.SERVICEHOST + Constants.SERVICEPATH,
    }
  } */


  login = values => {
    // Get a token from api server using the fetch api
    var formBody = [];
    for (var key in values) {
      if (values.hasOwnProperty(key)) {
        var encodeKey = encodeURIComponent(key);
        var encodeValue = encodeURIComponent(values[key]);
        formBody.push(encodeKey + '=' + encodeValue);
      }
    }
    formBody = formBody.join("&");
    return this.fetch('/tokens/v1', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      body: formBody
    }).then(res => {
      //this.setToken(res.token) // Setting the token in localStorage
      //console.log(this.getProfile())
      return res;//Promise.resolve(res);
    })
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token) // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
        return true;
      }
      else
        return false;
    }
    catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
  }

  checkClaims(claimName, checkwrite = false) {
    // TODO
    return true
    /* var token = this.getProfile();
    if (token === null || token === undefined) {
      return false;
    }
    var tmpClaim;
    for (var claim in token.claims) {
      if (token.claims[claim].claimName === claimName) {
        tmpClaim = token.claims[claim];
      }
    }
    if (tmpClaim === undefined) {
      return false;
    }
    if (checkwrite === true) {
      return tmpClaim.write;
    }
    return true; */
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
  }

  getProfile = () => {
    // Using jwt-decode npm package to decode the token
    var token = this.getToken();
    if (token === null || token === undefined) {
      return;
    }
    return decode(this.getToken());
  }


  fetch = (service, options) => {
    // performs api calls sending the required authentication headers
    Store.dispatch(setFetching(true))
    var headers;
    if (options !== undefined && options.headers !== undefined) {
      headers = options.headers;
    } else {
      headers = {
        'Content-Type': 'application/json',
      }
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    /* if (this.loggedIn()) {
      headers['Authorization'] = this.getToken()
    } */

    return fetch(this.domain + service, {
      headers,
      ...options
    })
      .then(response => {
        Store.dispatch(setFetching(false))
        if (response.status === 497) {
          return Promise.reject(response.error)
        }
        if (response.status === 204) {
          return null;
        }
        if (response.ok) {
          return response.json()
        }
        if (response.status === 401 && !window.location.pathname.includes("login")) {
          this.logout();
          window.location.href = Constants.URLS["LOGIN"]
          return;
        }
        return Promise.reject(Error("error"))
      }) //this._checkStatus(response.clone(), resolve, reject))
      .catch(error => error)
  }

  uploadFile(service, file, callback = null, body = null) {
    var formData = new FormData();
    if (file) {
      formData.append('file', window.encodeURIComponent(file.content));
      formData.append('filename', file.name);
      formData.append('filetype', file.customType);
      formData.append('description', file.description);
    }
    console.log(file)
    if (body != null) {
      formData.append('body', new Blob([JSON.stringify(body)], { type: "application/json" }));
    }
    var path = this.domain + service;
    var xmlHttpRequest = new XMLHttpRequest();
    if (callback != null) {
      xmlHttpRequest.addEventListener("load", (event) => callback(JSON.parse(event.target.response)))
    }
    xmlHttpRequest.open("POST", path, true);
    xmlHttpRequest.setRequestHeader("authorization", this.getToken());
    // Sending FormData automatically sets the Content-Type header to multipart/form-data
    xmlHttpRequest.send(formData);
  }

  renewToken() {
    this.fetch("users/token", {
      method: "GET",
    }).then(response => {
      this.setToken(response.token)
    })
  }

  _checkStatus(response, resolve, reject) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
      return response.json()
    } else if (response.status >= 400 && response.status < 500) {
      if (response.status === 401 && !window.location.pathname.includes("login")) {
        this.logout();
        window.location.href = Constants.URLS["LOGIN"]
        return;
      }
      if (response.status === 497) {
        alert(response.error);
      }
      return response.json()

    } else {
      var error = new Error(response.statusText)
      error.response = response
      console.log(response)
      throw error
    }
  }

  checkAllFetches(fetched, callback) {
    var int = setInterval(() => {
      if (fetched.every(el => el)) {
        clearInterval(int);
        if (typeof callback === 'function')
          callback();
      }
    }, 100);
  }
}


export default new AuthService()