import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import './LoadingBar.css'

class LoadingBar extends Component {
  render() {
    return (
      <Fragment>
        {this.props.fetching &&
          <div className="overlay" >
            <div className="weißerKasten">
              <div className="spinner">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            </div>
          </div>
        }
      </Fragment>
    )
  }
}

export default connect(
  store => ({
    fetching: store.FetchingReducer.fetching,
  })
)(LoadingBar);