import React, { Component, createRef } from 'react'
import { Map, Marker, Popup, FeatureGroup, LayersControl, ImageOverlay, GeoJSON } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L, { latLngBounds } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Female_Body from '../images/female_body.png'
import Female_Skeleton from '../images/female_skeleton.png'
import Female_Arterien from '../images/female_arterien.png'
import Female_Respiratory_System from '../images/female_respiratory_system.png'
import Female_Digestive_System from '../images/female_digestive_system.png'
import * as Service from '../Service';
import Control from 'react-leaflet-control';
import './bodySchema.css'
import { Button } from 'react-bootstrap';
import BodyPartBox from './BodyPartBox';
import DiseaseBox from './DiseaseBox';
import BodyPartPopup from './BodyPartPopup';

require('react-leaflet-markercluster/dist/styles.min.css');

export default class BodySchema extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLayer: "Atmungssystem",
            activeImage: Female_Body,
            showBodyParts: true,
            showDiseases: true,
        }
        this.mapRef = createRef();
    }

    componentDidMount() {
        /* var view = localStorage.getItem("graphView");
        console.log(view)
        if (view) {
            console.log(view)
            this.props.setActiveBodyPart(view);
            this.setState({
                activeLayer: "Atmungssystem"
            })
        } */
        if (true) {
            this.props.setActiveBodyPart("respiratory_system");
        }
        /* localStorage.removeItem("graphView");
        localStorage.removeItem("showBodyParts");
        localStorage.removeItem("showDiseases");
        this.setState({
            showBodyParts: showBodyParts,
            showDiseases: showDiseases,
        }) */
    }
    clickBodyPart = event => {
        if (event.target.feature.properties.name !== undefined) {
            var name = event.target.feature.properties.name.toLowerCase();
            this.props.setActiveBodyPart(name);
        }
    }

    handleBaseLayerChange = event => {
        this.setState({
            activeLayer: event.name,
        })
        if (event.name === "Atmungssystem") {
            this.props.setActiveBodyPart("respiratory_system");
        }
    }

    loadPicture = () => {
        import('../images/female_' + this.props.activeBodyPart + '.png')
            .then(picture => {
                this.setState({
                    activeImage: picture.default,
                })
            })
            .catch(() => false)
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeBodyPart !== prevProps.activeBodyPart) {
            this.loadPicture();
        }
    }


    render() {
        var diseaseIcon = L.icon({
            iconUrl: require('../images/disease_marker.png'),
            iconSize: [32, 32],
            iconAnchor: [0, 16]
        })
        var diseaseEtiologyIcon = L.icon({
            iconUrl: require('../images/diseaseEtiology_marker.png'),
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        })
        var bodyRegionIcon = L.icon({
            iconUrl: require('../images/bodyRegion_marker.png'),
            iconSize: [24, 24],
            iconAnchor: [16, 16]
        })
        const createClusterDiseaseIcon = function (cluster) {
            return L.divIcon({
                html: `<div><span>${cluster.getChildCount()}</span></div>`,
                className: 'marker-cluster-disease',
                iconSize: L.point(40, 40, true),
            });
        }
        const createClusterBodyRegionIcon = function (cluster) {
            return L.divIcon({
                html: `<div><span>${cluster.getChildCount()}</span></div>`,
                className: 'marker-cluster-bodyRegion',
                iconSize: L.point(40, 40, true),
            });
        }
        const createClusterDiseaseEthiologyIcon = function (cluster) {
            return L.divIcon({
                html: `<div><span>${cluster.getChildCount()}</span></div>`,
                className: 'marker-cluster-diseaseEtiology',
                iconSize: L.point(40, 40, true),
            });
        }
        const bounds = latLngBounds([[0, 0], [2735, 2000]])
        return (
            <Map bounds={bounds} style={{ height: "831px", width: "100%" }} maxZoom={3} minZoom={-2} crs={L.CRS.Simple}
                onBaseLayerChange={event => this.handleBaseLayerChange(event)} ref={this.mapRef}>
                <ImageOverlay
                    url={Female_Body}
                    bounds={bounds}
                />
                <LayersControl position="topright">

                    <LayersControl.BaseLayer name="Skelett">
                        <ImageOverlay
                            url={Female_Skeleton}
                            bounds={bounds}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Vaskuläres System">
                        <ImageOverlay
                            url={Female_Arterien}
                            bounds={bounds}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Atmungssystem" checked={this.state.activeLayer === "Atmungssystem"}>
                        <ImageOverlay
                            url={this.state.activeImage}
                            bounds={bounds}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Verdauungssystem">
                        <ImageOverlay
                            url={Female_Digestive_System}
                            bounds={bounds}
                        />
                    </LayersControl.BaseLayer>
                    <GeoJSON
                        key={this.props.activeBodyPart}
                        data={Service[this.props.activeBodyPart + "_geoJson"]}
                        style={feature => { return { opacity: 0, fillOpacity: 0 } }}
                        onEachFeature={(feature, layer) => {
                            layer.on({
                                click: (event) => { this.clickBodyPart(event); this.mapRef.current.leafletElement.setView([1367.5, 1000], -2); },
                                mouseover: () => layer.setStyle({ opacity: 1, fillOpacity: 0.1 }),
                                mouseout: () => layer.setStyle({ opacity: 0, fillOpacity: 0 })
                            });
                            layer.bindTooltip(feature.properties.title);
                        }}
                    />
                    <LayersControl.Overlay name="Krankheiten" checked={this.state.showDiseases}>
                        <FeatureGroup >
                            <MarkerClusterGroup iconCreateFunction={createClusterDiseaseIcon}>
                                {this.props.diseases.map((marker, index) => {
                                    return (
                                        <Marker position={marker.position} key={marker.position + index} title={marker.icdCode + " " + marker.title} icon={diseaseIcon} onClick={() => this.props.setActiveDisease(marker)}>
                                            {/* <Popup>
                                                <DiseaseBox {...marker} addFilter={this.props.addFilter} addToInfoBox={this.props.addToInfoBox} />
                                            </Popup> */}
                                        </Marker>
                                    )
                                })}
                            </MarkerClusterGroup>
                            <MarkerClusterGroup iconCreateFunction={createClusterDiseaseEthiologyIcon}>
                                {this.props.diseasesEtiology.map((marker, index) => {
                                    return (
                                        <Marker position={marker.position} key={marker.position + index} title={marker.icdCode + " " + marker.title} icon={diseaseEtiologyIcon} onClick={() => this.props.setActiveDisease(marker)}>
                                            {/* <Popup>
                                                <DiseaseBox {...marker} addFilter={this.props.addFilter} addToInfoBox={this.props.addToInfoBox} />
                                            </Popup> */}
                                        </Marker>
                                    )
                                })}
                            </MarkerClusterGroup>
                        </FeatureGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Körperregionen" checked={this.state.showBodyParts}>
                        <FeatureGroup >
                            <MarkerClusterGroup iconCreateFunction={createClusterBodyRegionIcon}>
                                {this.props.bodyInformations.map((marker, index) => {
                                    return (
                                        <Marker position={marker.position} key={marker.position} title={marker.title} icon={bodyRegionIcon}>
                                            <Popup>
                                                <BodyPartPopup bodyPart={marker.name} {...marker} addFilter={this.props.addFilter} addToInfoBox={this.props.addToInfoBox} />
                                            </Popup>
                                        </Marker>
                                    )
                                })}
                            </MarkerClusterGroup>
                        </FeatureGroup>
                    </LayersControl.Overlay>
                </LayersControl>
                <Control position="topright" >
                    <Button
                        variant="secondary"
                        onClick={() => { this.props.zoomOut(); this.mapRef.current.leafletElement.setView([1367.5, 1000], -2) }}
                    >
                        Zurück
                    </Button>
                </Control>

            </Map >
        )
    }
}