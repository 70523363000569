import { faMale, faMapMarked, faProjectDiagram, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Button, Form, FormControl, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { connect } from "react-redux";
import Speech from 'speak-tts';
import { addFilter, removeFilter, setLanguage, removeBookmark } from "../Redux/Actions";
import '../App.css';
import Filter from './Filter';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            place: null,
            lang: 'de',
            dist: 10,
        }
    }

    handleSearch = (event) => {
        console.log("handleSearch")
        event.preventDefault()
        if (this.state.place !== null) {
            Geocode.setApiKey("AIzaSyBxgv733foI0T875Bc0Q4L37TQFtc15h0k");
            Geocode.enableDebug();
            Geocode.fromAddress(this.state.place).then(
                response => {
                    var location = response.results[0].geometry.location;
                    var dist = parseInt(this.state.dist)
                    //this.props.handleRadialSearch(location, dist);
                    var item = { label: this.state.place, group: 3, position: location, dist: dist, type: "radial" }
                    this.props.addFilter(item)
                }
            )
        }
    }

    handleNav = (event) => {
        console.log(event)
        if (event === '4') {
            this.props.setLanguage("de")
        }
        if (event === '5') {
            this.props.setLanguage("en")
        }
    }

    render() {
        return (
            <Navbar variant="dark" expand="lg" onSelect={this.handleNav}>
                <Navbar.Brand href="#home" onClick={() => this.props.changeView("documents")}>medimaps</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">

                        <Nav.Item className="navIcon" onClick={() => this.props.changeView("graph")} title="Graph">
                            <FontAwesomeIcon icon={faProjectDiagram} />
                        </Nav.Item>

                        <Nav.Item className="navIcon" onClick={() => this.props.changeView("map")} title="Karte">
                            <FontAwesomeIcon icon={faMapMarked} />
                        </Nav.Item>
                        <Nav.Item className="navIcon" onClick={() => this.props.changeView("bodySchema")} title="Körper">
                            <FontAwesomeIcon icon={faMale} />
                        </Nav.Item>


                        <Nav.Item title="Einrichtungen">
                            <div className="resultBubble Facilities">
                                <span>
                                    {this.props.orgCount > 0 ? this.props.orgCount : ""}
                                </span>
                            </div>
                        </Nav.Item>
                        <Nav.Item title="Krankheiten">
                            <div className="resultBubble Diseases">
                                <span>
                                </span>
                            </div>
                        </Nav.Item>
                        <Nav.Item title="Regionen">
                            <div className="resultBubble Regions">
                                <span>
                                </span>
                            </div>
                        </Nav.Item>
                        <Nav.Item title="Anatomie">
                            <div className="resultBubble Anatomy">
                                <span>
                                </span>
                            </div>
                        </Nav.Item>
                    </Nav>
                    <Form inline onSubmit={(event) => this.handleSearch(event)}>
                        {/* <Button variant="link">
                            Notfall
                        </Button> */}
                        <NavDropdown title="Infobox" id="basic-nav-dropdown">
                            {this.props.bookmarks.map(item =>
                                <NavDropdown.Item key={item.title}>
                                    {item.title}
                                    <Button variant="link" onClick={() => this.props.removeBookmark(item)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </NavDropdown.Item>)}
                        </NavDropdown>
                        <Filter />
                        <NavDropdown eventKey={3} title="Sprache" id="basic-nav-dropdown">
                            <NavDropdown.Item eventKey={4}>Deutsch</NavDropdown.Item>
                            <NavDropdown.Item eventKey={5}>English</NavDropdown.Item>
                        </NavDropdown>
                        <Button variant="link">
                            <FontAwesomeIcon icon={faSignInAlt} />
                            &nbsp;Login
                        </Button>
                        {/* <Button variant="link" onClick={this.readPage}>
                            Vorlesen
                        </Button> */}
                        {(this.props.view === "graph" || this.props.view === "bodySchema") &&
                            <FormControl type="text" placeholder="Volltextsuche" className="mr-sm-2" />
                        }
                        {this.props.view === "map" &&
                            <Fragment>
                                <FormControl type="text" placeholder="Ort oder PLZ" className="mr-sm-2" onChange={event => this.setState({ place: event.target.value })} />
                                <FormControl as="select" onChange={event => this.setState({ dist: event.target.value })}>
                                    <option value={10}>10 km</option>
                                    <option value={20}>20 km</option>
                                    <option value={50}>50 km</option>
                                    <option value={100}>100 km</option>
                                </FormControl>
                            </Fragment>
                        }
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
        filter: store.FilterReducer.filter,
        orgCount: store.HospitalReducer.hospitals.length + store.PharmacyReducer.pharmacys.length + store.DoctorReducer.doctors.length,
        bookmarks: store.BookmarkReducer.bookmarks,
    }),
    { setLanguage, addFilter, removeFilter, removeBookmark }
)(Header);

