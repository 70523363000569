import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import * as Service from '../Service';
import { faFilter, faBookmark, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { addBookmark, addFilter, readText } from "../Redux/Actions";

class BodyPartBox extends Component {


    render() {
        var trans = {
            skin: "Körper",
            respiratory_system: "Atmungssystem",
            lower_respiratory_system: "Atmungssystem der Brust",
            lung: "Lunge",
            respiratory_system_head_neck: "Atmungssystem des Kopfes",
            trachea: "Luftröhre",
            larynx: "Kehlkopf",
        }
        return (
            <Fragment>
                <div className="card infoBox">
                    <div className="card card-header">
                            <Col md={9}>
                                {trans[this.props.bodyPart]}
                            </Col>
                            <Col md={3}>
                                <Button className="btn btn-sm btn-secondary mr-1" title="Filtern" onClick={() => this.props.addFilter(this.props)}>
                                    <FontAwesomeIcon icon={faFilter} />
                                </Button>
                                <Button className="btn btn-sm btn-secondary mr-1" title="Zur Infobox hinzufügen" onClick={() => this.props.addBookmark({ title: trans[this.props.bodyPart] })}>
                                    <FontAwesomeIcon icon={faBookmark} />
                                </Button>
                                <Button className="btn btn-sm btn-secondary" title="Vorlesen" onClick={() => this.props.readText(Service[this.props.bodyPart.toLowerCase() + "_text"])}>
                                    <FontAwesomeIcon icon={faVolumeUp} />
                                </Button>
                            </Col>
                    </div>
                    <div className="card card-body">
                        <Row>
                            <Col md={12}>
                                {Service[this.props.bodyPart.toLowerCase() + "_text"]}
                            </Col>
                        </Row>
                        {/*                       <Row>
                            <Col md={6}>
                                <Button variant="secondary" onClick={() => this.props.addToInfoBox(this.props.title)}>
                                    Merken
                        </Button>
                            </Col>
                        </Row> */}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default connect(
    store => ({
        language: store.LanguageReducer.language,
    }),
    { addBookmark, addFilter, readText }
)(BodyPartBox);