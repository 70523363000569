import React, { Fragment, Component } from 'react';
import Graph from './Graph';
import BodySchemaPage from './BodySchema/BodySchemaPage';
import MapView from './Map/MapView';
import Documents from './Documents/Documents';
import LoadingBar from './LoadingBar/LoadingBar';

export default class Content extends Component {

    render() {
        return (
            <Fragment>
                {this.props.view === "graph" &&
                    <Graph
                        addToInfoBox={this.props.addToInfoBox}
                        addToFilter={this.props.addToFilter}
                        filter={this.props.filter}
                        changeView={this.props.changeView}
                        hospitals={this.props.hospitals}
                        pharmacys={this.props.pharmacys}
                    />
                }
                {this.props.view === "map" &&
                    <MapView addToInfoBox={this.props.addToInfoBox} />
                }
                {this.props.view === "bodySchema" &&
                    <BodySchemaPage
                        addFilter={this.props.addToFilter}
                        addToInfoBox={this.props.addToInfoBox}
                    />
                }
                {this.props.view === "documents" &&
                    <Documents
                        changeView={this.props.changeView}
                    />
                }
                <LoadingBar />
            </Fragment>
        )
    }
};