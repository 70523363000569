import * as ActionTypes from './ActionsTypes'

export const setLanguage = language => ({
    type: ActionTypes.SET_LANGUAGE,
    payload: { language },
})

export const setFetching = fetching => ({
    type: ActionTypes.SET_FETCHING,
    payload: { fetching }
})

export const addFilter = filter => ({
    type: ActionTypes.ADD_FILTER,
    payload: { filter },
})

export const removeFilter = filter => ({
    type: ActionTypes.REMOVE_FILTER,
    payload: { filter },
})

export const setHospital = hospitals => ({
    type: ActionTypes.SET_HOSPITALS,
    payload: { hospitals },
})

export const setPharmacys = pharmacys => ({
    type: ActionTypes.SET_PHARMACYS,
    payload: { pharmacys },
})

export const setDoctors = doctors => ({
    type: ActionTypes.SET_DOCTORS,
    payload: { doctors },
})

export const addBookmark = bookmark => ({
    type: ActionTypes.ADD_BOOKMARK,
    payload: { bookmark },
})

export const removeBookmark = bookmark => ({
    type: ActionTypes.REMOVE_BOOKMARK,
    payload: { bookmark },
})

export const readText = text => ({
    type: ActionTypes.READ_TEXT,
    payload: { text },
})