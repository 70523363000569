import { ADD_BOOKMARK, REMOVE_BOOKMARK } from '../ActionsTypes';

const initialState = {
    bookmarks: [],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case ADD_BOOKMARK: {
            const { bookmark } = action.payload;
            return {
                ...state,
                bookmarks: [...state.bookmarks, bookmark],
            }
        }
        case REMOVE_BOOKMARK: {
            const { bookmark } = action.payload;
            return {
                ...state,
                bookmarks: state.bookmarks.filter(element => element.title !== bookmark.title)
            }
        }
        default: {
            return state;
        }
    }

}