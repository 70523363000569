import { ADD_FILTER, REMOVE_FILTER } from '../ActionsTypes';

const initialState = {
    filter: [],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case ADD_FILTER: {
            const { filter } = action.payload;
            var newFilter = state.filter.filter(element => element.type !== filter.type);
            return {
                ...state,
                filter: [...newFilter, filter],
            }
        }
        case REMOVE_FILTER: {
            const { filter } = action.payload;
            return {
                ...state,
                filter: state.filter.filter(element => element.label !== filter.label)
            }
        }
        default: {
            return state;
        }
    }

}